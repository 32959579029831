// import style css
import style from "../styles/SearchInput.module.scss"
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useSearch } from "../Contexts/Search";
import { useTranslation } from "react-i18next";

export default function SearchInput() {
	const {t}=useTranslation()
	const { searchTerm, setSearchTerm,  handleSearch } = useSearch()
	const navigate = useNavigate();

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
		  handleSearch(e.target.value);     
		  clearInput();
		  navigate(`/search?searchTerm=${searchTerm}`);
		}
	  };

	  const handleButtonClick = () => {
		handleSearch(searchTerm);
		clearInput();
		navigate(`/search?searchTerm=${searchTerm}`);
	  };

	  const clearInput = () => {
		setSearchTerm('');
	  };

	const handleChange=(e)=>{
      setSearchTerm(e.target.value)
	};
  return (
	<div className={style.searchWrapper}>
	<input 
	type="text" 
	value={searchTerm}
	onChange={handleChange}    
	onKeyDown={handleKeyDown}
	placeholder={t("search")} 
	className={style.searchInp}/>
	
               <Link className={style.searchIcon} to={`/search?searchTerm=${searchTerm}`}>
			  <CiSearch  onClick={()=>{ handleButtonClick() }} />
			  </Link>
	</div>
  )
}
