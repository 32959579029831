
const urls={
    settings:(lng)=>`/settings?lang=${lng}`,
    // settings:"/settings",
    contacts:(lng)=>`/branches?lang=${lng}`,
    categoriesName:(lng)=>`/categories?lang=${lng}`,   
    products:(lng,sort)=>`/products?lang=${lng}&sort=${sort}`,
    search:(lng)=>`/products?lang=${lng}&search=`   
        
          }
export default urls