// import style css
import style from "../styles/Header.module.scss"
// import react icons
import { IoLogoFacebook } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import Branches from "./Branches";
import { useEffect, useState } from "react"
import sirr from "../Helpers/Sirr"
import urls from "../ApiValues/urls"
import SearchInput from "./SearchInput";
import i18n from "../i18n";
import { Select } from '@chakra-ui/react'

export default function Header() { 
	const [settingsDatas, setSettingsDatas]=useState({})
	const [lng, setLng]=useState(sirr.lng)   

    useEffect(()=>{
		const defaultLang=localStorage.getItem("lang") || sirr.lng
		      sirr.lng=defaultLang
			  i18n.changeLanguage(defaultLang)   
			  setLng(defaultLang)
	},[])

	useEffect(()=>{
		const getHeaderDatas= async ()=>{
			try {
				const ResHeaderData= await sirr.api().get(urls.settings(lng))
					setSettingsDatas(ResHeaderData.data.data)
			} catch (error) {
			  console.log(error);	
			}
		} 
			 getHeaderDatas()
	},[lng])
	

	const onLanguageChange=(e)=>{
		const l=e.target.value
		i18n.changeLanguage(l)
		localStorage.setItem("lang", l)
		setLng(l)
	}
	//  console.log("settings data" , settingsDatas);
  return (
	<section id={style.header}>
		<div style={{padding:"0"}} className="container">
		<div className={style.headerBgWrapper}>
        <img src={`${sirr.baseUrlImage}/settings/header_image.jpg`} alt="header bg image" loading="lazy"/>
			<div className={style.overlay}></div>
			<div className={style.socialLogoLang}>
				<div className={style.social}>
				<a href={settingsDatas["site.social_facebook"]} target="_blank" rel="noreferrer"  className={style.facebook}><IoLogoFacebook /></a>
				<a href={settingsDatas["site.social_instagram"]} target="_blank" rel="noreferrer"  className={style.instagram}><RiInstagramFill /></a>
				</div>
	
				<a href="/"><img className={style.logo} src={`${sirr.baseUrlImage}/settings/site-logo.svg`} alt="" loading="lazy"/></a>

           <Select value={lng} onChange={onLanguageChange} w="60px" h="30" 
		   className={style.selectLang} 
			focusBorderColor="transparent"
			>
            <option value='az'>AZ</option>
            <option value='en'>EN</option>
            <option value='ru'>RU</option>    
              </Select>
			
			</div>
		</div>     
        <Branches/>
		<SearchInput/>
 		</div>
	</section>
  )
}
