// import style css
import style from "../styles/PrCart.module.scss"
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";
import sirr from "../Helpers/Sirr";

export default function PrCart({data}) {

		useEffect(()=>{
	    	Aos.init()   
		 },[]) 

  return (
      <div
          data-aos="zoom-in"
          data-aos-duration="1000"
          //  data-aos-easing="ease-in-sine"
          data-aos-offset="0"
          className={style.PrCartWrapper}
      >
          <img
              src={`${sirr.baseUrlImage}${data.image}`}      
              alt=""
              loading="lazy"
          />
          <div className={style.prDescription}>
              <h3 className={style.PrTitle}>{data.title}</h3>
              <p className={style.prComposition}>{data.description}</p>
              <div className={style.prPriceWeight}>
                  <span className={style.Weight}>{data.quantity} {data.unit}</span>
                  <hr className={style.line} />
                  <span className={style.price}>{data.price}₼</span>
              </div>    
          </div>
      </div>
  );
}
