import axios from "axios";
    

class SirrApp{
	constructor(){  
        this.lng="az";
        this.sort = 'az';
	this.baseUrl ="https://menu-admin.sirr.az/api";
	this.baseUrlImage="https://menu-admin.sirr.az/storage/"
        this.headers = {
            Accept:"application/json",
            "Content-Type":"application/json"      
        }   
	};
   
	api(){
        return axios.create({
            baseURL:this.baseUrl,  
            headers: this.headers
                  })
              }
};
const sirr = new SirrApp();
export default sirr        
