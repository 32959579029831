import React, { useEffect,useContext,useState,useParams } from 'react';
import PrCart from '../components/PrCart';
import style from "../styles/SearchResult.module.scss";
import { Link, useSearchParams } from 'react-router-dom';
import { useSearch } from '../Contexts/Search';
import ArrowIcon from "../assets/images/icons/ArrowIcon";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ApiGlobal } from "../Contexts/ApiGlobal";
// import image
import noResultFound from "../assets/images/icons/no-result-found.svg"
import CategoryNameAndImage from '../components/CategoryNameAndImage';
import { useTranslation } from 'react-i18next';

const SearchResult = () => {
	const {searchResult, setSearchTerm, emptyResult, handleSearch } = useSearch()    
	const [searchParams] = useSearchParams();
    const {t}=useTranslation();    
    const {categoryNameDatas} = useContext(ApiGlobal)

    
	useEffect(() => {      
		setSearchTerm(searchParams.get('searchTerm'));
		handleSearch(searchParams.get('searchTerm'));  
	},[])

  return (
    <section id={style.searchResult}>
      <div style={{paddingTop:"20px"}} className="container">

       <div className={style.categoryTitleWrapper}>
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}

						slideToClickedSlide={true}
                        className={style.mySwiper}
                    >
                        {categoryNameDatas.map((category) => (
                            <SwiperSlide
                                className={style.customClassname}
                                key={category.id}
                            >
                                <Link
                                   to={`/category/${category.slug}`}
                                    className={style.categoryName}
                                >
                                    {category.title}
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <hr className={style.line} />
              <div className={style.siteWay}>
                    <Link to="/" className={style.homePage}>
                       {t("homePage")}
                    </Link>
                    <ArrowIcon className={style.arrowIcon} />
                    <span className={style.currentPage}>{t("search")}</span>
                </div>
       
        <div className={style.searchResultWrapper}>
          {searchResult.length ? (
              <div className={style.searchResult}>
                  {searchResult.map((item) => (
                      <PrCart key={item.id} data={item} />
                  ))}
              </div>
          ) : emptyResult ? 
             (
				<div className={style.noResultFound}>
                   <img src={noResultFound} alt="" />
                <h4 className={style.searchValue}>"{searchParams.get('searchTerm')}" {t("NoProductFound")}</h4> 
                </div> 
		  )
          
          : (
              <div className={style.loading}>{t("loading")} ...</div>
          )}
		</div>
        <hr className={style.line} />
      <CategoryNameAndImage/>
      </div>
      </section>
  );
};

export default SearchResult;

